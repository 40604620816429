import React, {Component, Suspense, lazy} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import AuthMiddleware from './AuthMiddleware';


const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));

const PayinReport = lazy(() => import('./report/PayinReport'));
const PayoutReport = lazy(() => import('./report/PayoutReport'));
const BankReport = lazy(() => import('./report/BankReport'));
const LoanReport = lazy(() => import('./report/LoanReport'));
const Bank = lazy(() => import('./bank/Bank'));
const BankForm = lazy(() => import('./bank/BankForm'));
const ManualPayment = lazy(() => import('./bank/ManualPayment'));
const ManualBankPayment = lazy(() => import('./bank/ManualBankPayment'));
const CashPayment = lazy(() => import('./bank/CashPayment'));

const Order = lazy(() => import('./payment/Order'));
const Payment = lazy(() => import('./payment/Payment'));
const UPIPayment = lazy(() => import('./payment/UPIPayment'));
const IMPSayment = lazy(() => import('./payment/IMPSayment'));
const PaymentSuccessful = lazy(() => import('./payment/PaymentSuccessful'));
const OrderExpired = lazy(() => import('./payment/OrderExpired'));
const OrderTimeout = lazy(() => import('./payment/OrderTimeout'));
const UserList = lazy(() => import('./user/UserList'));
const CreateUser = lazy(() => import('./user/CreateUser'));
const SchemeMaster = lazy(() => import('./scheme-master/SchemeMaster'));
const CreateSchemeMaster = lazy(() => import('./scheme-master/CreateSchemeMaster'));
const CreateCommission = lazy(() => import('./bank/CreateCommission'));
const BankUPI = lazy(() => import('./bank/BankUPI'));
const WithdrawalForm = lazy(() => import('./withdrawal/WithdrawalForm'));
const ChangePassword = lazy(() => import('./user/ChangePassword'));
const PaymentSuccess = lazy(()=> import('./payment/WithdrawalSuccess')) ;
const wSuccessful = lazy(()=> import('./payment/wSuccessful')) ;
const PaymentReceipt = lazy(()=> import('./payment/PaymentReceipt')) ;
const TotalHit = lazy(()=> import('./report/TotalHit')) ;
const PaymentLink = lazy(()=> import('./user/PaymentLink')) ;
const ProfilePayment = lazy(()=> import('./user/ProfilePayment')) ;
const Setting = lazy(()=> import('./user/Setting')) ;
const EditProfile = lazy(() => import('./user/EditProfile'));
const EditUser = lazy(() => import('./user/EditUser'));

class AppRoutes extends Component {
    render() {
        return (
            <Suspense fallback={<Spinner/>}>
                <Switch>
                    <Route exact path="/login" component={Login}/>
                    <Route path="/register" component={Register1}/>


                    <Route path="/error-pages/error-404" component={Error404}/>
                    <Route path="/error-pages/error-500" component={Error500}/>
                    <AuthMiddleware>
                        <Route path="/dashboard" component={Dashboard}/>

                        <Route path="/payin-report" component={PayinReport}/>
                        <Route path="/payout-report" component={PayoutReport}/>
                        <Route path="/ledger-report" component={BankReport}/>
                        <Route path="/loan-report" component={LoanReport}/>

                        <Route path="/order" component={Order}/>
                        <Route path="/payment/:id" component={Payment}/>
                        <Route path="/bank" component={Bank}/>
                        <Route path="/bank-form" component={BankForm}/>
                        <Route path="/upi-payment/:id" component={UPIPayment}/>
                        <Route path="/imps-payment/:id" component={IMPSayment}/>
                        <Route path="/payment-successful/:id" component={PaymentSuccessful}/>
                        <Route path="/w-successful/:id" component={wSuccessful}/>
                        <Route path="/order-faild" component={OrderExpired}/>
                        <Route path='/withdrawal-success' component={PaymentSuccess}/>
                        <Route path='/payment-receipt/:id' component={PaymentReceipt}/>

                        <Route path="/scheme" component={SchemeMaster}/>
                        <Route path="/create-scheme" component={CreateSchemeMaster}/>
                        <Route path="/update-scheme/:id" component={CreateSchemeMaster}/>
                        <Route path="/create-commission" component={ CreateCommission } />
                        <Route path="/upi-bank" component={ BankUPI } />
                        <Route path="/withdrawal" component={ WithdrawalForm } />
                        <Route path="/payin-report-list/:status" render={(props) => <TotalHit {...props} transactionType='credit'/>} />
                        <Route path="/payout-report-list/:status" render={(props) => <TotalHit {...props} transactionType='debit'/>} />
                        {/*<Route path="/payin-total-hit" component={ TotalHit } transactionType='credit' />*/}
                        {/*<Route path="/payout-total-hit" component={ TotalHit } transactionType='debit' />*/}

                        <Route path="/order-expired" component={OrderTimeout}/>
                        <Route path="/manual-payment" component={ManualPayment}/>
                        <Route path="/manual-bank-payment" component={ManualBankPayment}/>
                        <Route path="/cash-payment" component={CashPayment}/>
                        <Route path="/user" component={UserList}/>
                        <Route path="/change-password" component={ChangePassword}/>
                        <Route path="/edit-profile" component={EditProfile}/>
                        <Route path="/edit-user/:id" component={EditUser}/>
                        <Route path="/setting" component={Setting}/>
                        <Route path="/create-user" component={CreateUser}/>
                        <Route path="/payment-link" component={PaymentLink}/>
                        <Route path="/profile-payment" component={ProfilePayment}/>
                        <Route path="/basic-ui/buttons" component={Buttons}/>
                        <Route path="/basic-ui/dropdowns" component={Dropdowns}/>
                        <Route path="/basic-ui/typography" component={Typography}/>

                        <Route path="/form-Elements/basic-elements" component={BasicElements}/>

                        <Route path="/tables/basic-table" component={BasicTable}/>

                        <Route path="/icons/mdi" component={Mdi}/>

                        <Route path="/charts/chart-js" component={ChartJs}/>
                        {/*<Route index component={Dashboard}/>*/}
                        {/*<Route path="/">
                            <Redirect to="/dashboard"/>
                        </Route>*/}
                        {/*<Redirect from="/" to="/dashboard"/>*/}
                    </AuthMiddleware>

                    <Redirect to="/login"/>
                    {/*<Redirect from="/" to="/dashboard"/>*/}
                </Switch>
            </Suspense>
        );
    }
}

export default AppRoutes;